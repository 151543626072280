<template>
    <Popup title="Edit Profile">
        <form>
            <FormGroup
                    v-model:parent_ref="username"
                    label="Username"
                    :disabled="true"
            />
            <FormGroup
                    v-model:parent_ref="formData.email"
                    label="Email"
                    type="text"
                    :check="(value) => {
                        return value.length >= fieldLimits.email.min && value.length <= fieldLimits.email.max && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
                    }"
            />
            <div class="form-group">
                <button
                        class="other-button other-button-left"
                        type="button"
                        @click.prevent="changePassword = !changePassword"
                >
                    Change password
                    <img
alt="Register" src="~/assets/icons/right_arrow.svg" width="14" height="14"
                         :style="{transform: changePassword ? 'rotate(90deg)' : 'rotate(0deg)'}">
                </button>
            </div>
            <template v-if="changePassword">
                <FormGroup
                        v-model:parent_ref="formData.current_password"
                        label="Current Password"
                        type="password"
                        autocomplete="password"
                />
                <FormGroup
                        v-model:parent_ref="formData.password"
                        label="New Password"
                        type="password"
                        autocomplete="new-password"
                        :check="(value) => {
                            return value.length >= fieldLimits.password.min && value.length <= fieldLimits.password.max && value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/);
                        }"
                />
                <FormGroup
                        v-model:parent_ref="formData.confirm_password"
                        label="New Password (Confirm)"
                        type="password"
                        :check="(value) => {
                            return value && value === formData.password;
                        }"
                />
            </template>
            <div class="form-group submit-group">
                <button type="submit" @click.prevent="edit">
                    Edit
                    <img alt="Register" src="~/assets/icons/right_arrow.svg">
                </button>
            </div>
        </form>
    </Popup>
</template>

<script setup>
import {usePopup} from "@/composables/usePopup";
import {notify} from "@kyvg/vue3-notification";

const {setPopup} = usePopup();

const changePassword = ref(false);

const {user, refreshUser} = useUser();

const username = user.value.username;

const formData = reactive({
    email: user.value.email,
    current_password: "",
    password: "",
    confirm_password: "",
});

// edit

const edit = async () => {
    let data = {
        email: formData.email !== user.value.email ? formData.email : undefined
    };

    if (
        formData.password ||
        formData.confirm_password ||
        formData.current_password
    ) {
        if (formData.password !== formData.confirm_password) {
            notify({
                type: "error",
                title: "Passwords do not match"
            })
            return;
        }

        data = {
            ...data,
            current_password: formData.current_password,
            new_password: formData.password,
        };
    }

    const response = await $fetch("/api/user/edit", {
        method: "POST",
        body: data,
        ignoreResponseError: true
    });

    if (response.status) {
        if (response.info) {
            notify({
                type: "info",
                title: response.info
            })
        } else {
            if (formData.password || formData.confirm_password || formData.current_password) {
                notify({
                    type: "info",
                    title: "Password has been changed"

                })
            } else {
                notify({
                    type: "info",
                    title: "Password has not been changed",
                })
            }

            notify({
                type: "success",
                title: "User settings edited successfully"
            })
        }
        setPopup(null);

        await refreshUser(useCookie("session").value);
    } else if (response.error) {
        notify({
            type: "error",
            title: response.error
        })
    } else {
        notify({
            type: "error",
            title: "An error occurred while editing the user settings"
        })
    }
};
</script>

